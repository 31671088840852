import React, { useState } from "react"
import {
  Wrapper,
  ImageWrapper,
  Rounded,
  NoImageWrapper,
  FormWrapper,
  RowWrapper,
  InputWrapper,
  Spacer,
  ButtonWrapper,
} from "./Ajouter.module.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import {
  PaddingBottom10,
  PaddingTop30,
} from "./../../../../css/Padding.module.css"
import Button from "../../../../components/Button"
import { classNames } from "../../../../helpers/classNames"
import AddImage from "./../../../../res/add_image.png"
import Select from "../../../../components/Select"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Ajouter = ({ offers, shops }) => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const [image, setImage] = useState({ preview: "", raw: "" })
  const [category, setCategory] = useState("")
  const [shop, setShop] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [welcomeOffer, setWelcomeOffer] = useState(null)

  const categories = [
    "Bars & Restauration",
    "Sports & Loisirs",
    "Mode & Bien être",
    "Services",
    "Jobs",
  ]
  const welcomeOffers = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
  ]

  function getCategoryOffersNumber(category) {
    var idx = 0
    var result = 0

    while (idx < offers.length) {
      if (offers[idx]["category"] === category)
        result++
      idx++;
    }
    return result
  }

  const newOffer = imagePath => {
    return {
      image: imagePath,
      category: category.value,
      title: title,
      welcomeOffer: welcomeOffer.value,
      isFavorite: false,
      description: description,
      shopId: shop.value,
      index: offers.length,
      categoryIndex: getCategoryOffersNumber(category.value),
      mask: false,
    }
  }

  const handleValidateFormButton = () => {
    if (
      image.preview === "" ||
      title.length === 0 ||
      category.length === 0 ||
      shop.length === 0 ||
      //(category.value === "Jobs" && contract.length === 0) ||
      //!welcomeOffer ||
      description.length === 0
    ) {
      setErrorSnackbarIsOpened(true)
      return
    }
    firebase
      .storage()
      .ref(`/offers/${image.raw.name}`)
      .put(image.raw)
      .then(() => {
        firebase
          .storage()
          .ref(`/offers/${image.raw.name}`)
          .getDownloadURL()
          .then(imageUrl => {
            firebase
              .firestore()
              .collection("offers")
              .add(newOffer(imageUrl.toString()))
              .then(() => {
                setSuccessSnackbarIsOpened(true)
              })
              .catch(addingError => {
                //console.log("addingError: ", addingError)
              })
          })
          .catch(imageUrlError => {
            //console.log("imageUrlError: ", imageUrlError)
          })
      })
      .catch(uploadUrlError => {
        //console.log("uploadUrlError: ", uploadUrlError)
      })
  }

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Ajouter une offre</h1>
      <form className={FormWrapper}>
        <div className={ImageWrapper}>
          <label htmlFor="upload-button">
            {image.preview ? (
              <img className={Rounded} src={image.preview} alt="offer" />
            ) : (
              <div className={NoImageWrapper}>
                <img
                  src={AddImage}
                  alt="offer"
                  width="75"
                  height="75"
                  style={{ paddingLeft: "37px", paddingTop: "37px" }}
                />
              </div>
            )}
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="category">Catégorie</label>
          </div>
          <Select
            placeholder="Sélectionnez une catégorie"
            options={categories.map(data => ({
              value: data,
              label: data,
            }))}
            onChange={e => setCategory(e)}
            value={category}
          />
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="title">Titre</label>
            </div>
            <input
              type="name"
              placeholder="ex: -10% sur ta prochaine commande"
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="shop">Commerce</label>
            </div>
            <Select
              placeholder="Quel commerce propose cette offre ?"
              options={shops.map(shop => ({
                value: shop.id,
                label: shop.name,
              }))}
              onChange={e => setShop(e)}
              value={shop.id}
            />
          </div>
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="welcomeOffer">Offre de bienvenue</label>
          </div>
          <Select
            placeholder="Cette offre est-elle une offre de bienvenue ?"
            options={welcomeOffers.map(data => ({
              value: data.value,
              label: data.label,
            }))}
            onChange={e => setWelcomeOffer(e)}
            value={welcomeOffer}
          />
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="description">Description</label>
          </div>
          <textarea
            type="message"
            placeholder="Décris l’offre ici..."
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className={classNames([PaddingTop30, RowWrapper])}>
          <div className={Spacer}></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleValidateFormButton()}>
              Ajouter
            </Button>
          </div>
        </div>
      </form>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as ajouté un nouvelle offre !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue lors de l'ajout de l'offre. Vérifie
          les informations que tu as rentré.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Ajouter
