import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import Ajouter from "./../../../nav/Admin/Offres/Ajouter";
import { Helmet } from "react-helmet";
import { getUser } from "./../../../auth";
import Error403 from "./../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import firebase from "gatsby-plugin-firebase";
import { getOfferFormatted } from "../../../utils/formating";

const HandleUserStates = ({ user, offers, shops }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Ajouter offers={offers} shops={shops} />;
  } else {
    return <Error403 />;
  }
};

const AjouterPage = () => {
  const [user, setUser] = useState(undefined);
  const [offers, setOffers] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    getUser(setUser);
    firebase
      .firestore()
      .collection("offers")
      .get()
      .then((docs) => {
        let idx = 0;
        let newOffers = [];

        while (idx < docs.docs.length) {
          newOffers.push(getOfferFormatted(docs.docs[idx]));
          idx++;
        }
        setOffers(newOffers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("shops")
      .get()
      .then((docs) => {
        let idx = 0;
        let newShops = [];

        while (idx < docs.docs.length) {
          newShops.push({
            name: docs.docs[idx]._delegate._document.data.value.mapValue.fields
              .name.stringValue,
            id: docs.docs[idx].id,
          });
          idx++;
        }
        setShops(newShops);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Ajouter une offre</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici un formulaire te permettant d'ajouter une offre."
        />
        <meta name="keywords" content="réductions,jeunes,admin,offre" />
        <link rel="canonical" href="https://wynit.com/admin/offres/ajouter" />
      </Helmet>
      <HandleUserStates user={user} offers={offers} shops={shops} />
    </Layout>
  );
};

export default AjouterPage;
